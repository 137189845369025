/* useful conversion for breakpoint
* see http://www.stubbornella.org/content/2013/07/01/easy-peasy-rem-conversion-with-sass/
*/
/* rem/px management
* use only if you need to support IE8
* see http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
* example of use 
* @include rem(font-size, 1.6rem);
* @include rem(padding, 20px 10px);
* will output:
*  font-size: 16px;  
*  font-size: 1.6rem;
*  padding: 20px 10px; 
*  padding: 2rem 1rem;
*/
/* breakpoints
* see http://thesassway.com/intermediate/responsive-web-design-in-sass-using-media-queries-in-sass-32
*/
/*
* multiple sprites management 
* see https://www.liquidlight.co.uk/blog/article/creating-svg-sprites-using-gulp-and-sass/
*/
.ref, .detail {
  margin-bottom: 30px;
  font-family: lato_bold, arial, sans-serif; }
  .ref span, .detail span {
    display: inline-block;
    min-width: 140px;
    margin-right: 30px;
    text-align: right;
    font-family: lato_reg, arial, sans-serif; }
  .ref strong, .detail strong {
    display: inline-block; }
  @media screen and (max-width: 1024px) {
    .ref strong, .ref span, .detail strong, .detail span {
      display: block;
      min-width: none;
      text-align: left; }
    .ref strong, .detail strong {
      margin-bottom: 15px;
      word-break: break-all; } }

.detail span {
  text-align: left;
  min-width: 240px;
  max-width: 240px; }
  @media screen and (max-width: 539px) {
    .detail span {
      min-width: 0; } }

.payment_type {
  margin: 30px 0; }
  .payment_type strong {
    color: #0077b4;
    vertical-align: top; }
  .payment_type img {
    display: inline-block;
    vertical-align: top;
    margin-left: 17px; }

table {
  width: 100%;
  border: 1px solid #e6e6e6; }

th, td {
  padding: 7px 15px 6px;
  text-align: left; }
  th.txt_right, td.txt_right {
    text-align: right; }

td {
  border-bottom: 1px solid #e6e6e6; }

thead, tfoot {
  background: #e6f1f7;
  font-family: lato_bold, arial, sans-serif;
  color: #00537d; }
  thead td.txt_right, tfoot td.txt_right {
    text-align: right; }
