/* useful conversion for breakpoint
* see http://www.stubbornella.org/content/2013/07/01/easy-peasy-rem-conversion-with-sass/
*/
/* rem/px management
* use only if you need to support IE8
* see http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
* example of use 
* @include rem(font-size, 1.6rem);
* @include rem(padding, 20px 10px);
* will output:
*  font-size: 16px;  
*  font-size: 1.6rem;
*  padding: 20px 10px; 
*  padding: 2rem 1rem;
*/
/* breakpoints
* see http://thesassway.com/intermediate/responsive-web-design-in-sass-using-media-queries-in-sass-32
*/
/*
* multiple sprites management 
* see https://www.liquidlight.co.uk/blog/article/creating-svg-sprites-using-gulp-and-sass/
*/
.intro {
  -webkit-align-items: center;
  align-items: center; }
  .intro p {
    max-width: 440px; }
    @media screen and (max-width: 1024px) {
      .intro p {
        max-width: 100%;
        margin-bottom: 30px; } }

.visual_help {
  position: relative; }
  .visual_help img {
    display: block;
    max-width: 100%; }
  @media screen and (max-width: 1024px) {
    .visual_help {
      width: 100%; }
      .visual_help img {
        margin: 0 auto;
        height: auto; } }
